import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import ContentCard from "./contentCard"
import { StaticImage } from "gatsby-plugin-image"

const SearchBlock = ({ cards, userHasSuscription }) => {
  return (
    <div className="mb-8 flex flex-wrap justify-center">
      {cards && cards.length > 0 ? (
        cards.map((tarjeta, key) => (
          <div className="sm:full p-2" key={key}>
            <ContentCard 
              userHasSuscription={userHasSuscription ?? null}
              card={tarjeta}
            />
          </div>
        ))
      ) : (
        <StaticImage
          alt="SHIFT"
          src="../../images/logo_gray.png"
          className="h-fit"
        />
      )}
    </div>
  )
}

export default SearchBlock
