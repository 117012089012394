import React, { useState, useEffect } from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { IconContext } from "react-icons";
import {IoFunnel} from 'react-icons/io5'
import FiltroOpcion from './FiltroOpcion'
import BackButton from './BackButton';
import "./style.css"

const FilterSearchButton = ({section, onSectionFilterChange, message}) => {
    
    const [filtros, setFiltros] = useState({
        nivel:[],
        lugar:[],
        coach:[],
        duracion:[],
        requiereEquipamiento:[],
        grupoMuscular:[],
        objetivo:[],
        })



        useEffect(() => {
            setFiltros({
                nivel: [],
                lugar: [],
                coach: [],
                duracion: [],
                requiereEquipamiento: [],
                grupoMuscular: [],
                objetivo: [],
            });
        }, [section]);
        
        const setOpcionEnFiltro = (checked, filtro, opcion) => {
            setFiltros(prevFiltros => {
                const filtroSeleccionado = [...prevFiltros[filtro]];
    
                if (checked) {
                    // Agregar si no existe
                    if (!filtroSeleccionado.includes(opcion)) {
                        filtroSeleccionado.push(opcion);
                    }
                } else {
                    // Eliminar la instancia de la opción
                    const index = filtroSeleccionado.indexOf(opcion);
                    if (index !== -1) {
                        filtroSeleccionado.splice(index, 1);
                    }
                }
    
                return {
                    ...prevFiltros,
                    [filtro]: filtroSeleccionado,
                };
            });
        };
        const filtrado = (section, filtros, onSectionFilterChange) => {
            // Verificar si no se ha seleccionado ningún filtro
            const noFiltersSelected = Object.values(filtros).every(filter => filter.length === 0);
        
            if (noFiltersSelected) {
                // Si no se ha seleccionado ningún filtro, mostrar todos los datos sin filtrar
                onSectionFilterChange(section);
            } else {
                // Aplicar los filtros seleccionados
                let result = section.filter((tarjeta) => {
                    if (tarjeta.entrenamiento) {
                        return search(
                            filtros,
                            tarjeta.entrenamiento.nivel,
                            tarjeta.entrenamiento.lugar,
                            tarjeta.entrenamiento.coach,
                            tarjeta.entrenamiento.duracion,
                            tarjeta.entrenamiento.requiereEquipamiento,
                            tarjeta.entrenamiento.grupoMuscular,
                            tarjeta.entrenamiento.objetivo
                        );
                    }
                    return false;
                });
        
                onSectionFilterChange(result);
            }
        };
        
      
    
    const search = (filtros, nivel,lugar,coach,tiempo,requiereEquipamiento,grupoMuscular,objetivo) => {
        
        let nivelPredicate = (nivel) => {
            if(!nivel || !filtros.nivel || filtros.nivel.length <= 0){
                return true
            }
            return filtros.nivel.filter(i => i.toLowerCase() === nivel.toLowerCase()).length > 0
        }

        let lugarPredicate = (lugar) => {
            if(!lugar || !filtros.lugar || filtros.lugar.length <= 0){
                return true
            }
            return filtros.lugar.filter(i => i.toLowerCase() === lugar.toLowerCase()).length > 0
        }

        let coachPredicate = (coach) => {
            if(!coach || !filtros.coach || filtros.coach.length <= 0){
                return true
            }
            return filtros.coach.filter(i => i === coach).length > 0
        }

        let tiempoPredicate = (tiempo) => {
            if (!tiempo || !filtros.tiempo || filtros.tiempo.length <= 0) {
                return true;
            }
            // Comprueba si algún filtro de tiempo está dentro del rango
            return filtros.tiempo.some(filtro => {
                const filtroNum = parseInt(filtro);
            
                let limiteInferior, limiteSuperior;

            
                switch (filtroNum) {
                    case 20:
                        limiteInferior = 0;
                        limiteSuperior = 20;
                        break;
                    case 30:
                        limiteInferior = 21;
                        limiteSuperior = 30;
                        break;
                    case 45:
                        limiteInferior = 31;
                        limiteSuperior = 45;
                        break;
                    case 60:
                        limiteInferior = 46;
                        limiteSuperior = 60;
                        break;
                    default:
                        return false; 
                }

                return tiempo >= limiteInferior && tiempo <= limiteSuperior;
            });

        }

        let requiereEquipamientoPredicate = (requiereEquipamiento) => {
            if(!requiereEquipamiento || !filtros.requiereEquipamiento || filtros.requiereEquipamiento.length <= 0){
                return true
            }
            return filtros.requiereEquipamiento.filter(i => i === requiereEquipamiento).length > 0
        }

        let grupoMuscularPredicate = (grupoMuscular) => {
            if(!grupoMuscular || !filtros.grupoMuscular || filtros.grupoMuscular.length <= 0){
                return true
            }
            return filtros.grupoMuscular.filter(i => i.toLowerCase() === grupoMuscular.toLowerCase()).length > 0
        }

        let objetivoPredicate = (objetivo) => {
            if(!objetivo || !filtros.objetivo || filtros.objetivo.length <= 0){
                return true
            }
            return filtros.objetivo.filter(i => i.toLowerCase() === objetivo.toLowerCase()).length > 0
        }
       
        return nivelPredicate(nivel) && lugarPredicate(lugar) && coachPredicate(coach) && tiempoPredicate(tiempo) && requiereEquipamientoPredicate(requiereEquipamiento) && grupoMuscularPredicate(grupoMuscular) && objetivoPredicate(objetivo)
    }
          
    // Simulación CoachesAPI
    const [coaches, setCoaches] = useState([])

    const getCoaches = () => {
        return {
            coaches: [
            {
                id: 1,
                nombre: "Pedro Ramírez",
                value: 1
            },
            {
                id: 2,
                nombre: "Oscar Vela",
                value: 2
            },
            {
                id: 3,
                nombre: "Laura Castellano",
                value: 3
            },
            {
                id: 4,
                nombre: "Jessica García",
                value: 4
            },
        ]
        }
    }

        // Restablecer filtros cuando cambia la sección (suponiendo que la sección cambia externamente)
        useEffect(() => {
            setFiltros({
                nivel: [],
                lugar: [],
                coach: [],
                tiempo: [],
                requiereEquipamiento: [],
                grupoMuscular: [],
                objetivo: [],
            });
        }, [section]);

    useEffect(() => {
        setCoaches([...getCoaches().coaches])
    }, [])


    // States y Funcs para el menú Dropdown | Estados y Funciones para el CSS 
    const [mostrandoMenuPrincipal, setMostrandoMenuPrincipal] = useState(false)
    const [mostrandoNivel, setMostrandoNivel] = useState(true)
    const [mostrandoLugares, setMostrandoLugares] = useState(true)
    const [mostrandoCoach, setMostrandoCoach] = useState(true)
    const [mostrandoTiempo, setMostrandoTiempo] = useState(true)
    const [mostrandoEquipamiento, setMostrandoEquipamiento] = useState(true)
    const [mostrandoGrupoMuscular, setMostrandoGrupoMuscular] = useState(true)
    const [mostrandoObjetivo, setMostrandoObjetivo] = useState(true)

    const mostrarMenuPrincipal = () => {
        setMostrandoMenuPrincipal(!mostrandoMenuPrincipal)
    }

    const mostrarFiltro = (seter, objective) => {
        seter(!objective)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        filtrado(section, filtros,onSectionFilterChange)
    }

    // Restablecer filtros cuando se selecciona "Todos los workouts"
    const handleAllworkouts = () => {
        setFiltros({
            nivel: [],
            lugar: [],
            coach: [],
            tiempo: [],
            requiereEquipamiento: [],
            grupoMuscular: [],
            objetivo: [],
        });
    
        // Llamar a onSectionFilterChange con la sección completa sin filtrar
        onSectionFilterChange(section);
    };
    

    return (
        <>
            <div className="filter-search-button-container">
                <div className="px-16 my-6 flex mb-4">
                <BackButton message={message} />

                </div>
                <div className="w-1/2 h-12 flex justify-end items-center">
                    <button onClick={handleAllworkouts} className="mr-6 p-0 px-8 font-bold py-2 rounded-full border border-black text-black text-sm active:bg-gray-200">
                        Todos los workouts
                    </button>
                        <IconContext.Provider value={{size:"1.4rem", }}>
                            <div className="cursor-pointer">
                                <IoFunnel onClick={mostrarMenuPrincipal}  ></IoFunnel>                      
                            </div>
                        </IconContext.Provider>    
                </div>
            </div>
            
            {/*Aqui va el Menu */}
            <form onSubmit={handleSubmit}>
                <div className={`absolute bg-gray-100 w-72 h-auto text-black font-light border rounded shadow-2xl z-30 transition-all duration-100 delay-100 ease-in-out right-6 sm:right-20 ${mostrandoMenuPrincipal ? " " : "hidden" }`}>

                        <div className="max-h-96 overflow-auto">

                            <div className="m-0.5 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Nivel</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon onClick={() => {mostrarFiltro(setMostrandoNivel,mostrandoNivel)}}
                                        style={mostrandoNivel ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}} 
                                        className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    <div className={` ${mostrandoNivel ? "" : "hidden"} `}>
                                        <FiltroOpcion filtro="nivel" option={{value:"bajo", nombre:"Principiante"}} onFilterChange={setOpcionEnFiltro}/>
                                        <FiltroOpcion filtro="nivel" option={{value:"medio", nombre:"Medio"}} onFilterChange={setOpcionEnFiltro}/>
                                        <FiltroOpcion filtro="nivel" option={{value:"alto", nombre:"Avanzado"}} onFilterChange={setOpcionEnFiltro}/>
                                    </div>
                                </div>

                            </div>
                            
                            {/*Lugares=> Status actual: hidden*/}
                            <div className="m-0.5 flex flex-col hidden">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Lugares</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoLugares,mostrandoLugares)}}
                                        style={mostrandoLugares ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    <div className={` ${mostrandoLugares ? "" : "hidden"} `}>
                                    
                                        <FiltroOpcion filtro="lugar" option={{value:"CentroDeportivo", nombre:"Centro Deportivo"}} onFilterChange={setOpcionEnFiltro}/>
                                        <FiltroOpcion filtro="lugar" option={{value:"Parque", nombre:"Parque"}} onFilterChange={setOpcionEnFiltro}/>
                                        <FiltroOpcion filtro="lugar" option={{value:"Playa", nombre:"Playa"}} onFilterChange={setOpcionEnFiltro}/>
                                        <FiltroOpcion filtro="lugar" option={{value:"Casa", nombre:"En Casa"}} onFilterChange={setOpcionEnFiltro}/>

                                    </div>
                                </div>

                            </div>

                            {/* <div className="m-0.5	 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Coach</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoCoach,mostrandoCoach)}}
                                        style={mostrandoCoach ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>

                                    <div className={` ${mostrandoCoach ? "" : "hidden"} `}>

                                    {coaches.length > 0 && coaches.map(coach => (
                                        <FiltroOpcion filtro="coach" option={coach} key={coach.id} onFilterChange={setOpcionEnFiltro}/>
                                    ))}

                                    </div>
                                </div>
                            </div> */}

                            <div className="m-0.5	 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Tiempo</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoTiempo,mostrandoTiempo)}}
                                        style={mostrandoTiempo ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    <div className={` ${mostrandoTiempo ? "" : "hidden"} `}>
                                        
                                        <FiltroOpcion filtro="tiempo" option={{value:20, nombre:"20 Minutos"}} onFilterChange={setOpcionEnFiltro}/>
                                        
                                        <FiltroOpcion filtro="tiempo" option={{value:30, nombre:"30 Minutos"}} onFilterChange={setOpcionEnFiltro}/>

                                        <FiltroOpcion filtro="tiempo" option={{value:45, nombre:"45 Minutos"}} onFilterChange={setOpcionEnFiltro}/>

                                        <FiltroOpcion filtro="tiempo" option={{value:60, nombre:"60 Minutos"}} onFilterChange={setOpcionEnFiltro}/>
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="m-0.5	 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Equipamiento</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoEquipamiento,mostrandoEquipamiento)}}
                                        style={mostrandoEquipamiento ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    
                                    <div className={` ${mostrandoEquipamiento ? "" : "hidden"} `}>
                                            
                                            <FiltroOpcion filtro="requiereEquipamiento" option={{value:true, nombre:"Sí"}} onFilterChange={setOpcionEnFiltro}/>

                                            <FiltroOpcion filtro="requiereEquipamiento" option={{value:false, nombre:"No"}} onFilterChange={setOpcionEnFiltro}/>

                                    </div>
                                </div>
                            </div>

                            <div className="m-0.5	 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Grupo Muscular</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoGrupoMuscular,mostrandoGrupoMuscular)}}
                                        style={mostrandoGrupoMuscular ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  
                                    className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    <div className={` ${mostrandoGrupoMuscular ? "" : "hidden"} `}>
                                        
                                        <FiltroOpcion filtro="grupoMuscular" option={{value:"Abdomen", nombre:"Abdomen"}} onFilterChange={setOpcionEnFiltro}/>

                                        <FiltroOpcion filtro="grupoMuscular" option={{value:"PiernaYGluteo", nombre:"Pierna y Glúteo"}} onFilterChange={setOpcionEnFiltro}/>

                                        <FiltroOpcion filtro="grupoMuscular" option={{value:"EspaldaYHombro", nombre:"Espalda Y Hombro"}} onFilterChange={setOpcionEnFiltro}/>

                                        <FiltroOpcion filtro="grupoMuscular" option={{value:"BicepsYTriceps", nombre:"Biceps y Triceps"}} onFilterChange={setOpcionEnFiltro}/>

                                    </div>
                                </div>
                            </div>

                            <div className="m-0.5	 flex flex-col">
                                <div className="flex flex-row justify-between items-center">
                                    <h4 className="ml-2 mt-3 pt-2 text-lg">Objetivo</h4>
                                    <div className="block mr-6">
                                        <PlayArrowIcon 
                                        onClick={() => {mostrarFiltro(setMostrandoObjetivo,mostrandoObjetivo)}}
                                        style={mostrandoObjetivo ? {transform: "rotate(90deg)"} : {transform: "rotate(0)"}}  className={`${mostrandoMenuPrincipal ? "cursor-pointer " : " "}`}></PlayArrowIcon>
                                    </div>
                                </div>
                                
                                <div className={`ml-5 ${mostrandoMenuPrincipal ? "" : "hidden"}`}>
                                    <div className={` ${mostrandoObjetivo ? "" : "hidden"} `}>
                                            
                                            <FiltroOpcion filtro="objetivo" option={{value:"AumentoCondicion", nombre:"Aumento De Condicion"}} onFilterChange={setOpcionEnFiltro}/>
                                    
                                            <FiltroOpcion filtro="objetivo" option={{value:"PerdidaPeso", nombre:"Perdida De Peso"}} onFilterChange={setOpcionEnFiltro}/>
                                    
                                            <FiltroOpcion filtro="objetivo" option={{value:"GananciaMuscular", nombre:"Ganancia Muscular"}} onFilterChange={setOpcionEnFiltro}/>
                                    
                                            <FiltroOpcion filtro="objetivo" option={{value:"AumentoFuerza", nombre:"Aumento De Fuerza"}} onFilterChange={setOpcionEnFiltro}/>
                                    
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="my-4 w-full flex justify-center">
                            <button className={` bg-gray-300  active:bg-gray-400  font-semibold p-1 w-11/12 rounded-md
                             ${mostrandoMenuPrincipal ? " " : " hidden"}`} >Aplicar filtro</button>
                        </div>

                </div>
            </form>
        </>
    )
}

export default FilterSearchButton
