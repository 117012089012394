import React from "react"
import { MdArrowBack } from "react-icons/md"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

const BackButton = ({ message }) => {
  const goBack = () => {
    navigate("/")
  }

  return (
    <button
      onClick={goBack}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        color: "black",
        fontWeight: "bold",
      }}
    >
      <MdArrowBack
        style={{ fontSize: "24px", color: "gray", marginRight: "8px" }}
      />
      {message}
    </button>
  )
}

BackButton.propTypes = {
  message: PropTypes.string.isRequired,
}

export default BackButton
